import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppContent } from '../../App.styles';
import { ActionButton, BlockActions, ButtonMain } from '../../common/styles';
import { IState } from '../../interfaces/system/IState';
import { IAuction } from '../../interfaces/auctions/IAuction';
import { useNavigate } from 'react-router-dom';
import { appConfig } from '../../config/app.config';
import ShopAnalytics from '../../components/shop/analytics/ShopAnalytics';
import { getAuctionList } from '../../actions/auctions/auction.actions';
import { ContentBold, ContentHead, PageDesc } from '../../common/typos';
import CatalogCarousel from '../../components/shop/catalog/CatalogCarousel';
import { IProductPreviewOrder } from '../../interfaces/shop/IProduct';
import { loadProductsBatchNext } from '../../actions/shop/product.actions';
import { sendPageEvent } from '../../utils/analytics';
import { IShopAnalytics } from '../../interfaces/shop/IShopAnalytics';
import { setOrderForCatalog } from '../../actions/shop/shop.actions';
import { loadIDsByFoundations } from '../../actions/account/sellers.actions';
import { Block } from '../../common/layout';
import AuctionItemModeration from '../../components/auctions/AuctionItemModeration';
import { getElementId } from '../../utils/layout.utils';
import { DataBatchWide } from '../../interfaces/system/data';
import { Trans } from 'react-i18next';
import { L } from '../../common/locale';
import { getPlatformType, getRewriteConfig, platformConfigRewrite } from '../../config/platform.config';
import { navigateToAuction } from '../../utils/routes';

interface IMainPageProps {
  loadProductsBatchNext: (order: IProductPreviewOrder) => any;
  getAuctionList: () => void;
  loadIDsByFoundations: () => void;
  setOrderForCatalog: (order: null | IProductPreviewOrder) => any;
  auctionNext: undefined | null | IAuction;
  auctionByID: Record<string, IAuction>;
  auctionIDs: string[];
  shopAnalytics: undefined | null | IShopAnalytics;
  batch: Record<IProductPreviewOrder, DataBatchWide>;
  foundationProductIDs: string[];
}

function MainPage(props: IMainPageProps) {
  const navigate = useNavigate();
  const [auctionsCompleted, setAuctionsCompleted] = useState<IAuction[]>([]);
  const [auctionsCompletedCnt, setAuctionsCompletedCnt] = useState<
    number | null
  >(null);
  const [analytics, setAnalytics] = useState<undefined | null | IShopAnalytics>(
    undefined,
  );

  const [isProductsExists, setIsProductsExists] = useState<boolean>(false);
  useEffect(() => {
    setIsProductsExists(
      (props.batch.new.IDs && !!props.batch.new.IDs[0]) ||
      (props.batch.liked.IDs && !!props.batch.liked.IDs[0]) ||
      (props.batch.popular.IDs && !!props.batch.popular.IDs[0]),
    );
  }, [props.batch.new.IDs, props.batch.liked.IDs, props.batch.popular.IDs]);

  useEffect(() => sendPageEvent('Main'), []);
  useEffect(() => {
    setAnalytics(props.shopAnalytics);
  }, [props.shopAnalytics]);
  useEffect(() => {
    props.getAuctionList();
    props.loadIDsByFoundations();
    if (props.batch.new.loaded === 0) {
      props.loadProductsBatchNext('new');
    }
    if (props.batch.popular.loaded === 0) {
      props.loadProductsBatchNext('popular');
    }
    if (props.batch.liked.loaded === 0) {
      props.loadProductsBatchNext('liked');
    }
  }, []);

  useEffect(() => {
    const localCompleted: IAuction[] = [];

    props.auctionIDs.forEach((auctionID: string) => {
      const auction: null | IAuction = props.auctionByID[auctionID] || null;
      if (!auction) {
        return;
      }

      if (auction.isComplete) {
        localCompleted.push(auction);
      }

      localCompleted.sort((a, b) => ((a.ind || 0) > (b.ind || 0) ? 1 : -1));
      setAuctionsCompleted(localCompleted);
      setAuctionsCompletedCnt(localCompleted.length);
    });

    if (localCompleted.length === 1 && getRewriteConfig()?.hardRedirectToComplete) {
      navigateToAuction(localCompleted[0], navigate);
    }
  }, [props.auctionByID]);

  const goToCatalog = (order: null | IProductPreviewOrder) => {
    props.setOrderForCatalog(order);
    navigate(appConfig.routes.shopProducts.list);
  };
  const goToFoundations = () => {
    navigate(appConfig.routes.shopProducts.foundations);
  };
  const goToAddLot = () => {
    navigate(appConfig.routes.shop.addLot);
  };

  return (
    <AppContent>
      <Block>
        {!props.auctionNext && !auctionsCompleted[0] && (
          <PageDesc>
            <L k={'AuctionsTitleEmpty'} />
          </PageDesc>
        )}
        {props.auctionNext && (
          <>
            <ContentHead>
              * <L k={'AuctionsTitleUpcoming'} />
            </ContentHead>
            <AuctionItemModeration
              auction={props.auctionNext}
              isClickable={true}
              showInline={false}
            />
          </>
        )}
        {auctionsCompleted[0] && (
          <>
            <ContentHead>
              <L k={'AuctionsTitleCompleted'} />
            </ContentHead>
            {auctionsCompleted.map((auction: IAuction, index: number) => (
              <>
                {props.auctionNext && (
                  <AuctionItemModeration
                    auction={auction}
                    isClickable={true}
                    showInline={false}
                    showTotal={auctionsCompletedCnt === index + 1}
                    hideImage={true}
                  />
                )}

                {!props.auctionNext && (
                  <AuctionItemModeration
                    auction={auction}
                    key={auction.id}
                    isClickable={true}
                    showInline={false}
                    showTotal={true}
                  />
                )}
              </>
            ))}
          </>
        )}

        {props.auctionNext && (
          <>
            {analytics && isProductsExists && (
              <ContentHead
                className="clickable"
                onClick={() => goToCatalog(null)}
              >
                <L k={'ShopTitleMain'} />:
              </ContentHead>
            )}
            {!getRewriteConfig()?.hideShopAnalytics && <ShopAnalytics />}

            {!getRewriteConfig()?.showOnlyPopularLots && <>

              {props.batch.new.IDs && props.batch.new.IDs[0] && (
                <>
                  <ContentHead
                    onClick={() => goToCatalog('new')}
                    className="clickable"
                  >
                    <Trans
                      i18nKey={'LotTitleFresh'}
                      components={{ bold: <ContentBold /> }}
                    ></Trans>
                  </ContentHead>

                  <CatalogCarousel
                    noView={true}
                    hideAnalytics={true}
                    previewIDs={props.batch.new.IDs}
                  />
                  <ActionButton
                    onClick={() => goToCatalog('new')}
                    id={getElementId('button-lots-fresh')}
                  >
                    <L k={'ButtonLotsFresh'} />
                  </ActionButton>
                </>
              )}

              {props.batch.liked.IDs && props.batch.liked.IDs[0] && (
                <>
                  <ContentHead
                    onClick={() => goToCatalog('liked')}
                    className="clickable"
                  >
                    <Trans
                      i18nKey={'LotTitleLiked'}
                      components={{ bold: <ContentBold /> }}
                    ></Trans>
                  </ContentHead>
                  <CatalogCarousel
                    noView={true}
                    hideAnalytics={true}
                    previewIDs={props.batch.liked.IDs}
                  />
                  <ActionButton onClick={() => goToCatalog('liked')}>
                    <L k={'ButtonLotsFresh'} />
                  </ActionButton>
                </>
              )}

              {props.batch.popular.IDs && props.batch.popular.IDs[0] && (
                <>
                  <ContentHead
                    onClick={() => goToCatalog('popular')}
                    className="clickable"
                  >
                    <Trans
                      i18nKey={'LotTitlePopular'}
                      components={{ bold: <ContentBold /> }}
                    ></Trans>
                  </ContentHead>
                  <CatalogCarousel
                    noView={true}
                    hideAnalytics={true}
                    previewIDs={props.batch.popular.IDs}
                  />
                  <ActionButton onClick={() => goToCatalog('popular')}>
                    <L k={'ButtonLotsPopular'} />
                  </ActionButton>
                </>
              )}

              {props.foundationProductIDs[0] && (
                <>
                  <ContentHead
                    onClick={() => goToFoundations()}
                    className="clickable"
                  >
                    <Trans
                      i18nKey={'LotTitlePartners'}
                      components={{ bold: <ContentBold /> }}
                    ></Trans>
                  </ContentHead>
                  <CatalogCarousel
                    noView={true}
                    hideAnalytics={true}
                    previewIDs={props.foundationProductIDs}
                  />
                </>
              )}
            </>}
            {getRewriteConfig()?.showOnlyPopularLots && props.batch.popular.IDs && props.batch.popular.IDs[0] && (
              <>
                <CatalogCarousel
                  noView={true}
                  hideAnalytics={true}
                  previewIDs={props.batch.popular.IDs}
                />
                <ActionButton onClick={() => goToCatalog('popular')}>
                  <L k={'ButtonLotsAll'} />
                </ActionButton>
              </>)}

            {!getRewriteConfig()?.hideAddLot
              && props.batch.new.IDs
              && props.batch.new.IDs[0]
              && (
                <BlockActions>
                  <ButtonMain onClick={goToAddLot}>
                    <L k={'ButtonLotAdd'} />
                  </ButtonMain>
                </BlockActions>
              )}

          </>
        )}

        {/*todo lots which was add after your last visit*/}
      </Block>
    </AppContent>
  );
}

export default connect(
  (state: IState) => ({
    auctionIDs: state.Auction.auctions.IDs,
    auctionByID: state.Auction.auctions.set,
    auctionNext: state.Auction.auctionNext,
    shopAnalytics: state.ShopProductAnalytics.shopAnalytics,
    batch: state.ShopProduct.batchPreview.batch,
    foundationProductIDs: state.ShopProduct.foundation.productIDs,
  }),
  {
    loadProductsBatchNext,
    loadIDsByFoundations,
    getAuctionList,
    setOrderForCatalog,
  },
)(MainPage);
