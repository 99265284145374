import React, { useEffect, useState } from 'react';
import { ContentBold, ContentDesc } from '../../common/typos';
import { getFullPrice } from '../../utils/price';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { IAccount, IAccountSet } from '../../interfaces/account/IAccount';
import { useNavigate } from 'react-router-dom';
import { appConfig } from '../../config/app.config';
import { makePathFromName } from '../../utils/routes';
import { loadAccountsSet } from '../../actions/account/accounts.actions';
import { ILotBuyer } from '../../interfaces/auctions/ILotPublic';
import { Loading } from '../../common/animation/Loading';
import { IArchiveReportLot } from '../../interfaces/archive/IArchiveReportLot';
import { Block } from '../../common/layout';
import AccountImage from '../account/AccountImage';
import { getRewriteConfig } from '../../config/platform.config';

interface IReportBuyersBlockProps {
  loadAccountsSet: (accountIDs: string[]) => void;
  reportLot: IArchiveReportLot;
  showAccounts?: boolean;
  accounts: IAccountSet;
}

function ReportBuyersBlock(props: IReportBuyersBlockProps) {
  const navigate = useNavigate();

  const [buyerIDs, setBuyerIDs] = useState<string[]>([]);
  const [buyerSet, setBuyerSet] = useState<null | IAccountSet>(null);
  const [buyerSetPrice, setBuyerSetPrice] = useState<null | Record<
    string,
    number
  >>(null);

  useEffect(() => {
    const { buyerList } = props.reportLot;
    if (!buyerList) {
      return;
    }

    const buyerIDsLocal: string[] = [];
    const buyerSetPriceLocal: Record<string, number> = {};
    const buyerSetLocal: IAccountSet = {};
    const buyerIDsToLoad: string[] = [];
    buyerList.forEach((buyerItem: ILotBuyer) => {
      const { buyerID, price } = buyerItem;
      buyerIDsLocal.push(buyerID);
      buyerSetPriceLocal[buyerID] = price;
    });
    setBuyerSetPrice(buyerSetPriceLocal);

    if (!buyerIDs || !buyerIDs[0]) {
      setBuyerIDs(buyerIDsLocal);
    }

    buyerIDsLocal.forEach((buyerID: string) => {
      const buyerLocal: null | IAccount =
        (buyerID && props.accounts[buyerID]) || null;
      if (buyerLocal) {
        buyerSetLocal[buyerID] = buyerLocal;
      } else {
        buyerIDsToLoad.push(buyerID);
      }
    });
    setBuyerSet(buyerSetLocal);
    if (buyerIDsToLoad[0]) {
      props.loadAccountsSet(buyerIDsToLoad);
    }
  }, [props.reportLot, props.accounts]);

  const goToUserLegend = (account: null | IAccount) => {
    if (!account) {
      return;
    }
    const accountPath: string = makePathFromName(account.name);
    const path = appConfig.routes.hall.legendByName.replace(
      appConfig.params.accountPath,
      accountPath,
    );
    navigate(path);
  };

  const renderBuyer = (buyerID: string) => {
    const buyer: null | IAccount = (buyerSet && buyerSet[buyerID]) || null;
    const price: null | number =
      (buyerSetPrice && buyerSetPrice[buyerID]) || null;
    if (!buyer) {
      return;
    }

    if (getRewriteConfig()?.hideAccountInfo) {
      return <ContentDesc>
        Donation:
        {price ? ' ' + getFullPrice(price) : ''}
      </ContentDesc>;
    }

    return (
      <Block
        onClick={() => goToUserLegend(buyer)}
        className={'clickable noMargin'}
        key={buyerID}
      >
        <ContentDesc>
          Donator: <ContentBold>{buyer.name}</ContentBold>
          {price ? ' - ' + getFullPrice(price) : ''}
        </ContentDesc>
        {(props.showAccounts && (
            <AccountImage accountID={buyer.id} size={'M'} />
          )) ||
          ''}
      </Block>
    );
  };

  return (
    <>
      {buyerIDs ? (
        buyerIDs.map((buyerID: string) => (
          <Block key={buyerID} className={'noMargin'}>
            {renderBuyer(buyerID)}
          </Block>
        ))
      ) : (
        <Loading size="small" />
      )}
    </>
  );
}

export default connect(
  (state: IState) => ({
    accounts: state.Account.accounts.set,
  }),
  {
    loadAccountsSet,
  },
)(ReportBuyersBlock);
