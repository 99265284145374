import { appConfig } from './app.config';
import ShopAnalytics from '../components/shop/analytics/ShopAnalytics';

export type IPlatformType = keyof IPlatforms<any>;

export const PlatformTypeList: IPlatformType[] = [
  'rhythmburo',
  'aimprosoft',
  'artukraine',
  'keenethics',
  'zoola',
  // 'example',
  // 'tochka',
  'local',
];
export const PlatformTypeProdList: IPlatformType[] = [
  'rhythmburo',
  'aimprosoft',
  'artukraine',
  'keenethics',
  // 'tochka',
  'zoola',
];

export interface IPlatforms<T> {
  Main: T;
  rhythmburo: T;
  aimprosoft: T;
  artukraine: T;
  keenethics: T;
  zoola: T;
  // example: T;
  // tochka: T;
  // shukLive: T;
  // shukWeek: T;
  local: T;
}

export const platformConfigs: IPlatforms<string> = {
  Main: 'https://create.auction',
  rhythmburo: 'https://rhythmburo.create.auction',
  aimprosoft: 'https://aimprosoft.create.auction',
  artukraine: 'https://artukraine.create.auction',
  keenethics: 'https://keenethics.create.auction',
  zoola: 'https://zoola.create.auction',
  // example: 'https://example.create.auction',
  // tochka: 'https://tochka.create.auction',
  // shukLive: 'https://shuk-live.create.auction',
  // shukWeek: 'https://shuk-week.create.auction',
  local: 'https://create-auction-app.web.app',
};

export const platformPageTitle: IPlatforms<string> = {
  Main: 'Create Auction',
  rhythmburo: 'Rhythm Büro Charity Auction',
  aimprosoft: 'Aimpro.Soft Charity Auction',
  artukraine: 'ART Ukraine Auction',
  keenethics: 'Keenethics Charity Auction',
  zoola: 'Zoola Charity Auction',
  // example: 'Example Auction',
  // tochka: 'Аукціон Точки Збору',
  // shukLive: '[тестовий] Аукціон Подільского Шуку (лайв)',
  // shukWeek: '[тестовий] Аукціон Подільского Шуку (тижневий)',
  local: 'Local Auction',
  // local: 'ART Ukraine Auction',
};

export function getPlatformType(): IPlatformType {
  const { isDev, devPlatform } = appConfig;
  if (isDev && devPlatform) {
    if (devPlatform === 'rhythmburo') {
      return 'rhythmburo';
    } else if (devPlatform === 'artukraine') {
      return 'artukraine';
    } else if (devPlatform === 'aimprosoft') {
      return 'aimprosoft';
    } else if (devPlatform === 'keenethics') {
      return 'keenethics';
    } else if (devPlatform === 'zoola') {
      return 'zoola';
    } else {
      return 'local';
    }
  }

  return getRealPlatformType();
}

export function getRealPlatformType(): IPlatformType {
  const origin: string = window.location.origin;
  if (origin === platformConfigs.rhythmburo) {
    return 'rhythmburo';
  } else if (origin === platformConfigs.artukraine) {
    return 'artukraine';
  } else if (origin === platformConfigs.aimprosoft) {
    return 'aimprosoft';
  } else if (origin === platformConfigs.keenethics) {
    return 'keenethics';
  } else if (origin === platformConfigs.zoola) {
    return 'zoola';
  } else {
    // return 'temp';
    return 'local';
  }
}

export function getPlatformHeader(): string {
  const platformType: IPlatformType = getPlatformType();
  if (platformType === 'rhythmburo') {
    return platformPageTitle.rhythmburo;
  } else if (platformType === 'artukraine') {
    return platformPageTitle.artukraine;
  } else if (platformType === 'aimprosoft') {
    return platformPageTitle.aimprosoft;
  } else if (platformType === 'keenethics') {
    return platformPageTitle.keenethics;
  } else if (platformType === 'zoola') {
    return platformPageTitle.zoola;
  } else {
    return platformPageTitle.local;
  }
}

export function checkIsExample(): boolean {
  // return getPlatformType() === 'example';
  return false;
}

interface IPlatformConfigRewrite {
  hideLiveAuction: boolean;
  hideHall: boolean;
  hideAuctions: boolean;
  hideAddLot: boolean;
  hideAuctionDate: boolean;
  hideShopAnalytics: boolean;
  hideFooterSign: boolean;
  hideWeekReportsTopSellers: boolean;
  hideAccountInfo: boolean;
  hardRedirectToWeek: boolean;
  hardRedirectToComplete: boolean;
  showOnlyPopularLots: boolean;
  auctionTimeByKyiv: boolean;
}

export const platformConfigRewrite: Partial<IPlatforms<Partial<IPlatformConfigRewrite>>> = {
  rhythmburo: {
    hideLiveAuction: true,
    hideHall: true,
    hideAuctions: true,
    hideAddLot: true,
    hideAuctionDate: true,
    hideShopAnalytics: true,
    hideFooterSign: true,
    hideWeekReportsTopSellers: true,
    hideAccountInfo: true,
    hardRedirectToWeek: true,
    hardRedirectToComplete: true,
    showOnlyPopularLots: true,
    auctionTimeByKyiv: true,
  },
};

export function getRewriteConfig(): Partial<IPlatformConfigRewrite> {
  const platformType: IPlatformType = getPlatformType();
  return platformConfigRewrite[platformType] || {};
}

