import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { IAuction } from '../../interfaces/auctions/IAuction';
import { useNavigate, useParams } from 'react-router-dom';
import { appConfig } from '../../config/app.config';
import { ActionButton, BlockActions } from '../../common/styles';
import { getAuctionByPath } from '../../actions/auctions/auction.actions';
import { Loading } from '../../common/animation/Loading';
import AuctionItemModeration from './AuctionItemModeration';
import { L } from '../../common/locale';
import { getRewriteConfig } from '../../config/platform.config';

interface IAuctionInfoProps {
  getAuctionByPath: (auctionPath: string) => void;
  IDByPath: Record<string, string>;
  ByID: Record<string, IAuction>;
  showAllActions?: boolean;
  hideDonationLink?: boolean;
}

function AuctionInfo(props: IAuctionInfoProps) {
  const navigate = useNavigate();
  const { auctionPath } = useParams();

  const [auction, setAuction] = useState<null | IAuction>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!auctionPath) {
      return navigate(appConfig.routes.auctions.list);
    }

    if (props.IDByPath[auctionPath]) {
      return;
    }

    props.getAuctionByPath(auctionPath);
  }, []);
  useEffect(() => {
    if (auction || !auctionPath) {
      return;
    }

    const id: null | string = props.IDByPath[auctionPath] || null;
    setIsLoading(false);
    setAuction((id && props.ByID[id]) || null);
  }, [props.IDByPath]);

  const goToAuctions = () => {
    navigate(appConfig.routes.auctions.list);
  };

  return (
    <>
      {auction && (
        <AuctionItemModeration
          auction={auction}
          isClickable={true}
          showInline={false}
          hideDonationLink={props.hideDonationLink}
        />
      )}

      {isLoading && <Loading />}
      {props.showAllActions && !getRewriteConfig()?.hardRedirectToComplete && (
        <BlockActions>
          <ActionButton onClick={() => goToAuctions()}>
            <L k={'AuctionAll'} />
          </ActionButton>
        </BlockActions>
      )}
    </>
  );
}

export default connect(
  (state: IState) => ({
    IDByPath: state.Auction.auctions.IDByPath,
    ByID: state.Auction.auctions.set,
  }),
  {
    getAuctionByPath,
  },
)(AuctionInfo);
